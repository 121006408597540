import React, { useContext, useState } from "react"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { SpaceContext } from "@hooks/SpaceContext"
import { Button, Nav, TabContent, TabPane } from "reactstrap"
import Hours from "@components/Tabs/Hours"
import OperatorDetails from "@components/Tabs/OperatorDetails"

const tabs = [
  {
    name: "Operational Details",
    slug: "operationalDetails",
  },
  {
    name: "Business Details",
    slug: "businessDetails",
  },
]

const OperatorSettingsPage = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].slug)

  const {
    data: { soData, user },
  } = useContext(SpaceContext)

  return (
    <>
      <SEO title="Operator Settings" />
      <Layout title={"Operator Settings"} progress={!user || !user.id}>
        <Nav pills className="page-content-tabs">
          {tabs.map(({ name, slug }) => {
            return (
              <Button
                key={slug}
                color="default"
                className={`edit-space-tab-nav-link ${
                  activeTab === slug && "active"
                }`}
                onClick={() => setActiveTab(slug)}
              >
                {name}
              </Button>
            )
          })}
        </Nav>
        <div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={tabs[0].slug}>
              <Hours so_id={soData.id} data={soData} />
            </TabPane>
            <TabPane tabId={tabs[1].slug}>
              <OperatorDetails so_id={soData.id} data={soData} />
            </TabPane>
          </TabContent>
        </div>
      </Layout>
    </>
  )
}
export default OperatorSettingsPage
