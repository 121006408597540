import React, { useState } from "react"
import Section from "../../../templates/EditSpace/fields/Section"
import Input from "../../../templates/EditSpace/fields/Input"
import { useAuth } from "@auth/useAuth"
import { Button, Col, Row } from "reactstrap"
import { toast } from "react-toastify"

const OperatorDetails = ({ so_id, data = {} }) => {
  const { makeRequest } = useAuth()

  const [progress, setProgress] = useState(false)

  const [legal_business_name, set_legal_business_name] = useState(
    data.legal_business_name || ""
  )

  const [address, set_address] = useState(data.address || "")

  const [cancellation_deadline_in_hours, set_cancellation_deadline_in_hours] =
    useState(data.cancellation_deadline_in_hours || "")

  const saveBusinessData = (e) => {
    if (e) {
      e.preventDefault()
    }
    setProgress(true)
    makeRequest("so/edit", {
      id: so_id,
      data: {
        legal_business_name,
        address,
        cancellation_deadline_in_hours: Number(
          cancellation_deadline_in_hours || 48
        ),
      },
    })
      .then(function (res) {
        setProgress(false)
        toast.success("Details saved!")
      })
      .catch((e) => {
        setProgress(false)
        toast.error("Failed to save the details")
      })
  }

  return (
    <React.Fragment>
      <Section title={"Business Details"} first>
        <Row>
          <Col lg="6">
            <Input
              type="text"
              label={"Legal Business Name"}
              value={legal_business_name}
              onChange={(value) => set_legal_business_name(value)}
            />
            <Input
              type="text"
              label={"Address"}
              value={address}
              onChange={(value) => set_address(value)}
            />
            <Input
              type="select"
              label={"Cancellation Deadline"}
              value={cancellation_deadline_in_hours}
              onChange={(value) => set_cancellation_deadline_in_hours(value)}
            >
              <option value="">None</option>
              <option value="24">24 Hours</option>
              <option value="48">48 Hours</option>
            </Input>
          </Col>
          <Col md="12">
            <Button
              color={"success"}
              onClick={saveBusinessData}
              disabled={
                progress ||
                !legal_business_name ||
                !cancellation_deadline_in_hours
              }
            >
              Save
            </Button>
          </Col>
        </Row>
      </Section>
    </React.Fragment>
  )
}

export default OperatorDetails
